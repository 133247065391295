import "react-app-polyfill/ie9.js";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { FronteggProvider, FronteggThemeOptions } from "@frontegg/react";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { useFeatureFlagPayload, PostHogProvider } from "posthog-js/react";

import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import App from "./components/App.js";
import StatusBanner from "./components/SecondaryComponents/StatusBanner.js";

import "../public/styles.css";

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
});

// Redirect component to navigate to the main app
function RedirectToApp() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/");
	}, [navigate]);

	return null; // Render nothing
}

if (process.env.REACT_APP_ENVIRONMENT_TYPE === "production") {
	// Override console.log
	// console.log = () => {};
	// Override console.warn
	// console.warn = () => {};
	// You can also override other console methods if needed
}
const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

const initializeApp = async () => {
	// try {
	// 	const LDProvider = await asyncWithLDProvider({
	// 		clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
	// 		options: {
	// 			streaming: true,
	// 		},
	// 	});

	// 	root.render(
	// 		<Router>
	// 			<FronteggProvider
	// 				contextOptions={contextOptions}
	// 				themeOptions={themeOptions}
	// 				authOptions={{
	// 					routes: { signUpSuccessUrl: "/", authenticatedUrl: "/" },
	// 				}}
	// 			>
	// 				<LDProvider>
	// 					<App />
	// 				</LDProvider>
	// 			</FronteggProvider>
	// 		</Router>
	// 	);
	// } catch (error) {
	// 	console.error("Failed to initialize LaunchDarkly:", error);
	function AppWrapper() {
		const bannerPayload = useFeatureFlagPayload("banner-flag");

		return <>{bannerPayload && <StatusBanner statusBannerText={bannerPayload.bannerText} />}</>;
	}
	root.render(
		<PostHogProvider client={posthog}>
			<AppWrapper />
			<Router>
				<FronteggProvider
					hostedLoginBox
					contextOptions={contextOptions}
					localizations={localizations}
					themeOptions={themeOptions}
					entitlementsOptions={{ enabled: true }}
					authOptions={{
						keepSessionAlive: true,
						loadUserOnFirstLoad: true,
						routes: { signUpSuccessUrl: "/", authenticatedUrl: "/" }
					}}
				>
					<Routes>
						<Route path="/*" element={<App />} />
						{/*
						<Route path="/edu/*" element={<RedirectToApp />} />  Redirect all /edu paths
						<Route path="/*" element={<App />} />
						*/}
					</Routes>
				</FronteggProvider>
			</Router>
		</PostHogProvider>
	);
	// }
};

// FRONTEGG
const fronteggDomain = process.env.REACT_APP_FRONTEGG_DOMAIN;
const fronteggClientId = process.env.REACT_APP_FRONTEGG_CLIENT_ID;
const fronteggAppId = process.env.REACT_APP_FRONTEGG_APP_ID;
console.log(fronteggDomain);
const contextOptions = {
	baseUrl: fronteggDomain,
	clientId: fronteggClientId,
	appId: fronteggAppId
};

const useBreadCrumbs = !!process.env.REACT_APP_SENTRY_BREADCRUMBS;

const localizations = {
	en: {
		loginBox: {
			login: {
				continue: "Sign in"
			}
		}
	}
};

const themeOptions = {
	loginBox: {
		// pageFooter: () => (
		// 	<div
		// 		style={{
		// 			textAlign: "center",
		// 			marginTop: "15px",
		// 			fontSize: "12px",
		// 			lineHeight: "16px",
		// 			color: "#36373C"
		// 		}}
		// 	>
		// 		"Note: Mobile devices are currently not supported."
		// 	</div>
		// ),
		boxFooter: () => {
			let accId;
			if (window.location.search.indexOf("accId-") !== -1) {
				accId = window.location.search.split("accId-")[1].split("%3F")[0];
			}

			const searchParams = new URLSearchParams(window.location.search);
			let redirect_url = searchParams.get("redirectUrl");

			// Modify redirect_url as needed
			if (redirect_url) {
				redirect_url = encodeURIComponent(redirect_url.replace(/\//g, "@"));
				// Directly append the modified redirectUrl to the searchParams
				searchParams.set("redirectUrl", redirect_url);
			}

			// Construct the signup URL with all query parameters preserved
			// If accId exists, append it to the path; otherwise, just use the base signup path
			const signupUrlBase = `${window.location.origin}/signup`;
			const signupUrl = accId ? `${signupUrlBase}/${accId}?${searchParams.toString()}` : `${signupUrlBase}?${searchParams.toString()}`;

			if (accId !== undefined && redirect_url !== undefined) {
				return (
					<>
						<div
							style={{
								textAlign: "center",
								margin: "25px 0 0.5rem",
								fontSize: "2rem",
								fontWeight: "bold",
								// lineHeight: '2.5rem',
								color: "rgb(60, 74, 90)"
							}}
						>
							Need an account?{" "}
							{/* <a
									href={`${window.location.origin}/signup/${accId}/${redirect_url}`}
									rel="noreferrer"
									target="_blank"
									style={{ color: "rgb(0, 169, 175)" }}
								>
									Sign Up Here
								</a> */}
						</div>
						<div
							style={{
								textAlign: "center",
								marginTop: "5px"
							}}
						>
							<a href={signupUrl} rel="noreferrer" className="signUpButton">
								Sign up
							</a>
						</div>
					</>
				);
			}
		}
	}
};

// Sentry.init({
// 	dsn: "https://3f31e8a4faae46078e64e91e4932cfc7@o4504299738890240.ingest.sentry.io/4505053536452608",
// 	environment: process.env.REACT_APP_ENVIRONMENT_TYPE,

// 	integrations: [
// 		new Sentry.BrowserTracing(),
// 		new Sentry.Replay(),
// 		new Sentry.Integrations.Breadcrumbs({
// 			console: useBreadCrumbs
// 		})
// 	],
// 	// Performance Monitoring
// 	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
// 	// Session Replay
// 	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const renderApp = (Component) => {
	initializeApp(Component);
};

renderApp(App);

if (module.hot) {
	module.hot.accept("./components/App", () => {
		const NextApp = require("./components/App").default;
		renderApp(
			<PostHogProvider client={posthog}>
				<NextApp />
			</PostHogProvider>
		);
	});
}
