import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { obfuscate } from "../UtilityFunctions/DataUtility.js";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import s from "../../styleModules/signupStyles.module.css";
import styles from "../../styleModules/modalStyles.module.css";
import logo from "../../images/logo.png";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import LanguageDropDownComponent from "../SecondaryComponents/LanguageDropDownComponent.js";
import LanguageDict from "./LanguageDict.js";
import { useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react";
import StatusBanner from "../SecondaryComponents/StatusBanner.js";
import { useLoginWithRedirect } from "@frontegg/react";

class UserTypes {
	// Create new instances of the same class as static attributes
	static Licensed = new UserTypes("Licensed");

	static Basic = new UserTypes("Basic");

	toString() {
		return this.name;
	}

	constructor(name) {
		this.name = name;
		// UserRoles.prototype.toString = this.toString;
	}
}

class UserRoles {
	// Create new instances of the same class as static attributes
	static Member = new UserRoles("Member");

	static Owner = new UserRoles("Owner");

	static Admin = new UserRoles("Admin");

	static Faculty = new UserRoles("Faculty");

	toString() {
		return this.name;
	}

	constructor(name) {
		this.name = name;
		// UserRoles.prototype.toString = this.toString;
	}
}
class UserMetaData {
	constructor(type, role, group) {
		this.type = type;
		this.role = role;
		this.group = group;
		Object.preventExtensions(this);
	}
}
export default function SignUpPage() {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const navigate = useNavigate();

	const url = process.env.REACT_APP_BACKEND_STATIC_URL;

	const { clientId } = useParams();
	const [type, setType] = useState(UserTypes.Licensed);
	const [role, setRole] = useState(UserRoles.Member);
	const [isValidAccount, setIsValidAccount] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [clientName, setClientName] = useState("");
	const [nameInput, setNameInput] = useState("");
	const [emailInput, setEmailInput] = useState("");
	const [language, setLanguage] = useState("en");
	const [nameError, setNameError] = useState("");
	const [emailError, setEmailError] = useState("");
	const [signupError, setSignupError] = useState("");
	const [userExists, setUserExists] = useState(false);
	const [emailSubscribe, setEmailSubscribe] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [totalSeats, setTotalSeats] = useState(0);
	const [usedSeats, setUsedSeats] = useState(0);
	const { localUser, userLanguage, setUserLanguage, translateSingleText, performTranslation } = useContext(AppContext);

	const multiLanguageFlag = useFeatureFlagEnabled("multi-language-support");

	const [renderState, setRenderState] = useState(true);
	const [textHasBeenRendered, setTextHasBeenRendered] = useState(false);
	const loginWithRedirect = useLoginWithRedirect();

	// Static Translation
	const LocalLanguageDict = {
		SignUpEnterEmailPlaceholder: "Enter your email",
		SignUpEnterNamePlaceholder: "Enter your name",
		SignUpSubscribePlaceholder: "Subscribe to our newsletter",
		AlreadyHaveAccount: "Already have an InStage account?",
		SignInHere: "Sign in here",
		SignUpTitle: "Sign Up - InStage",
		SignUpDescription: "Sign up for a new InStage Account.",
		Email: "Email",
		Name: "Name",
		Language: "Language",
		SignUp: "Sign Up",
		ByContinuingIAgreeToTheInStage: "By continuing I agree to the InStage",
		TermsOfService: "Terms of Service",
		And: "and",
		PrivacyPolicy: "Privacy Policy"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
			setRenderState(!renderState);
		};

		translateText();
	}, [userLanguage]); // Depend on userLanguage to trigger re-translation

	useEffect(() => {
		setTextHasBeenRendered(true);
	}, [renderState]);

	const searchParams = new URLSearchParams(window.location.search);
	let redirect_url = searchParams.get("redirectUrl");

	// Modify redirect_url as needed
	if (redirect_url) {
		redirect_url = decodeURIComponent(redirect_url);
		redirect_url = redirect_url.replace(/@/g, "/");
	}

	const cantSave = false;
	const warning = "";
	let licenseCountColor;

	useEffect(() => {
		console.log("Sign Up Page");
	});

	useEffect(() => {
		if (clientId) {
			fetchData();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientId]);

	useEffect(() => {
		if (userLanguage) {
			setLanguage(userLanguage);
		}
	}, [userLanguage]);

	useEffect(() => {
		// This code runs when `userLanguage` changes.
		// You can place logic here that needs to react to language changes.
		console.log("Language changed to:", userLanguage);
		// Perform any action that needs to happen right after language change.
	}, [userLanguage, language]);

	async function fetchData() {
		setIsLoading(true);

		const body = {
			useV3: true,
			tenantId: clientId
		};

		const config = { params: body };

		try {
			console.log("GetTenantData");
			console.log(`${url}/api/users/GetTenantData`);

			const resp = await axiosLimitedGet(`${url}/api/users/GetTenantData`, 1, config);
			console.log("test", resp.data);
			if (resp.data.name) {
				setClientName(resp.data.name);
			} else {
				setClientName(null);
				setIsValidAccount(false);
			}
		} catch (error) {
			if (error.response) {
				console.error("GetTenantData Error", error.response);
			} else {
				console.error("GetTenantData Error", error);
			}
			setIsValidAccount(false);
			setIsLoading(false);
			return;
		}

		// try {
		//     let resp2 = await axiosLimitedGet(`${url}/api/users/GetUserManagerData`, 1, config)

		// 	let parsedData2 = resp2.data

		// 	setUsedSeats(parsedData2.usedSeats)
		// 	setTotalSeats(parsedData2.totalSeats)
		// } catch (error) {
		// 	console.log("GetUserManagerData Error", error)
		// 	setIsLoading(false);
		// }

		setIsLoading(false);
	}

	const handleInputChange = (e) => {
		const { type, value } = e.target;

		if (type === "text") {
			setNameError("");
			setNameInput(value);
		} else if (type === "email") {
			setEmailError("");
			setEmailInput(value);
		}
	};
	const validateEmail = (email) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};
	const validateName = (name) => {
		const re = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
		return re.test(name);
	};
	const validate = (data) => {
		setNameError("");
		setEmailError("");

		const errors = { nameError: "", emailError: "" };

		if (!validateEmail(data.email)) {
			setEmailError("Please enter a valid email");
			errors.emailError = "Please enter a valid email";
		}

		if (data.email.length === 0) {
			setEmailError("This field is required");
			errors.emailError = "This field is required";
		}

		if (data.name.length < 2) {
			setNameError("Please enter a minimum of 2 letters");
			errors.nameError = "Please enter a minimum of 2 letters";
		}

		if (!validateName(data.name)) {
			setNameError("Please enter a valid name");
			errors.nameError = "Please enter a valid name";
		}

		return errors;
	};

	function InviteUser() {
		setIsSubmitted(true);

		const data = {
			name: nameInput,
			email: emailInput
		};

		// Confirm that all inputs are valid
		const inputValidation = validate(data);

		if (inputValidation.nameError !== "" || inputValidation.emailError !== "") {
			console.log("invalid - check errors");
			console.log(inputValidation);
			setIsSubmitted(false);
			return;
		}

		const metaData = new UserMetaData(type, role, "default");
		SignUpUser(emailInput, nameInput, metaData);
	}

	async function SignUpUser(email, name, metadata) {
		const body = {
			tenantId: clientId,
			metadata,
			email,
			name,
			language,
			redirect_url,
			emailSubscribe,
			userLanguage
		};

		try {
			let resp;
			if (window.location.hostname.includes("localhost")) {
				resp = await axiosLimitedPost(`${url}/api/users/signUpUserMember`, body, 1, {}, 30000);
			} else {
				resp = await axiosLimitedPost(`${url}/api/users/signUpUserMember`, body, 1, {}, 30000);
			}

			console.log(resp.data);
			navigate("/signup-confirmed");
		} catch (error) {
			console.error("SignUpUser Error", error.response);
			setIsSubmitted(false);
			if (error.response.data && error.response.data === "User already exists") {
				setUserExists(true);
				setTimeout(() => {
					setUserExists(false);
					navigate(`/oauth/account/login${redirect_url ? `?redirectUrl=${redirect_url}` : ""}`);
				}, 3000);
			} else {
				setSignupError("This email may already exist. Please sign in.");
			}
		}

		fetchData();
	}

	function handleLogin() {
		window.localStorage.setItem("FRONTEGG_AFTER_AUTH_REDIRECT_URL", `${redirect_url}`);
		loginWithRedirect();

		//	href={`${window.location.origin}/oauth/account/login${redirect_url ? `?redirectUrl=${redirect_url}` : ""}`}
	}

	return (
		<div>
			<Helmet>
				<title>{pageText.SignUpTitle}</title>
				<meta name="description" content={pageText.SignUpDescription} />
				<meta name="robots" content="noindex,nofollow" />
			</Helmet>
			<div className={s.signUpPage}>
				{isLoading && (
					<div className={s.signUpAlign}>
						<LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />
					</div>
				)}

				{!isLoading && !isValidAccount && (
					<div className={s.signUpAlign}>
						<p style={{ color: "red", fontFamily: "BoldFont", fontSize: "20px" }}>Invalid account name</p>
						<p>Please check this URL is correct.</p>
					</div>
				)}

				{!isLoading && isValidAccount && userExists && (
					<div className={s.signUpAlign}>
						<h1 className={s.adminBoxTitle}>User already exists</h1>
						<div className={s.spinnerContainerSignUp}>
							<LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />
						</div>
						<p className={s.adminBoxText}>Redirecting to sign in page...</p>
					</div>
				)}

				{!isLoading && isValidAccount && !userExists && (
					<div className={s.signUpAlign}>
						<div className={s.adminBoxWrapper}>
							<div className={s.adminBox}>
								<div className={s.adminBoxHeader}>
									<img className={s.adminBoxLogo} src={logo} alt="logo" />
								</div>
								<h1 className={s.adminBoxTitle}>{`Join the ${clientName} Account`}</h1>
								<p>
									{pageText.AlreadyHaveAccount}
									<span> </span>
									<button
										type="button"
										style={{ color: "#00a9af", background: "none", border: "none", padding: 0, cursor: "pointer" }}
										onClick={handleLogin}
									>
										{pageText.SignInHere}
									</button>
								</p>
								{signupError !== "" && <p className={s.inputError}>{signupError}</p>}
								<p style={{ marginTop: "2px", marginBottom: "2px" }}>{pageText.Email}:</p>
								<input
									className={s.adminBoxInput}
									placeholder={pageText.SignUpEnterEmailPlaceholder}
									type="email"
									value={emailInput}
									onChange={handleInputChange}
									readOnly={isSubmitted}
								/>
								{emailError !== "" && <p className={s.inputError}>{emailError}</p>}
								<p style={{ marginTop: "2px", marginBottom: "2px" }}>{pageText.Name}:</p>
								<input
									className={s.adminBoxInput}
									placeholder={pageText.SignUpEnterNamePlaceholder}
									type="text"
									value={nameInput}
									onChange={handleInputChange}
									readOnly={isSubmitted}
								/>
								{nameError !== "" && <p className={s.inputError}>{nameError}</p>}
								{/* <span className={s.warning} style={{ backgroundColor: licenseCountColor }}>{warning}</span> */}
								{/* <label>Select Language:</label>
                            <select
                                className={s.adminBoxInput}
                                value={language}
                                onChange={() => setLanguage(event.target.value)}
                                disabled={isSubmitted}
                            >
                                <option value="en">English</option>
                                <option value="fr">French</option>
                                <option value="es">Spanish</option>
                            </select> */}
								{multiLanguageFlag && (
									<>
										<p style={{ marginTop: "2px", marginBottom: "2px" }}>{pageText.Language}:</p>
										<LanguageDropDownComponent className={styles.modalTextContainerCenter} style={{ marginBottom: "0px" }} />
										<br />

										<input
											className={s.togglebox}
											type="checkbox"
											id="emailSubscribe_toggle"
											checked={emailSubscribe}
											onChange={() => setEmailSubscribe(!emailSubscribe)}
										/>
										<label htmlFor="emailSubscribe_toggle">
											<p>{pageText.SignUpSubscribePlaceholder}</p>
										</label>
									</>
								)}

								{!isSubmitted && (
									<>
										<button
											type="button"
											className={`${s.adminBoxConfirm} ${cantSave ? s.disabled : ""}`}
											onClick={InviteUser}
											disabled={cantSave}
										>
											{pageText.SignUp}
										</button>
										{cantSave && (
											<p className={s.inputError}>
												Your account has no available licenses. Please contact your system administrator.
											</p>
										)}

										{!cantSave && (
											<div>
												{/* <div
													style={{
														textAlign: "center",
														marginTop: "15px",
														fontSize: "12px",
														lineHeight: "16px",
														color: "#36373C"
													}}
												>
													Note: Mobile devices are currently not supported.
												</div> */}
												<div
													style={{
														textAlign: "center",
														marginTop: "30px",
														fontSize: "12px",
														lineHeight: "16px",
														color: "#36373C"
													}}
												>
													{pageText.ByContinuingIAgreeToTheInStage}{" "}
													<a
														target="_blank"
														rel="noopener noreferrer"
														style={{ color: "#36373C", textDecoration: "underline" }}
														href="https://www.instage.io/terms"
													>
														{pageText.TermsOfService}
													</a>{" "}
													{pageText.And}{" "}
													<a
														target="_blank"
														rel="noopener noreferrer"
														style={{ color: "#36373C", textDecoration: "underline" }}
														href="https://www.instage.io/privacy"
													>
														{pageText.PrivacyPolicy}
													</a>
												</div>
											</div>
										)}
									</>
								)}

								{isSubmitted && (
									<div className={s.spinnerContainer}>
										<LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
